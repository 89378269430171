$primary: #287FD0;
$nav-tabs-border-width: 1px;

$font-family-sans-serif: Gilroy, sans-serif;

$font-weight-lighter: 300 !default;
$font-weight-light: 400 !default;
$font-weight-normal: 500 !default;
$font-weight-bold: 700 !default;
$font-weight-bolder: 900 !default;

/*
$font-weight-lighter:         300 !default;
$font-weight-light:           400 !default;
$font-weight-normal:          700 !default;
$font-weight-bold:            900 !default;
//$font-weight-bolder:          bolder !default;
*/

$border-width: 1px !default;

$enable-shadows: true;
$btn-box-shadow: false;

//$body-color: rgb(102, 102, 102);

$headings-font-weight: 700;

$text-muted: #868686;

/* Navbar */

$navbar-dark-brand-color: #fff;
$navbar-dark-active-color: #fff;
$navbar-dark-disabled-color: #787878;

/* Radius */

$border-radius: 0.2rem;

/* Dropdown */

$dropdown-border-width: 0;
$dropdown-link-color: rgb(102, 102, 102);

/* Alert */

$alert-border-width: 0;
$alert-margin-bottom: 0;

/* Buttons */

button:disabled {
    cursor: not-allowed;
    pointer-events: all !important;
}

/* Input */

$input-placeholder-color: #B9B9B9;

/* Input group */

$input-group-addon-bg: white;
$input-group-addon-color: #B9B9B9;

/* Table */

$table-color: rgb(102, 102, 102);

/* Modal */

$modal-content-border-color: rgb(246, 246, 246);
$modal-inner-padding: 3rem !important;

$modal-header-padding-y: 1rem !default;
$modal-header-padding-x: 3rem !default;

/* Bootstrap */
$font-path: '../../../../libs/assets/fonts';
$img-path: '../../../../libs/assets/img';

$displays: none, inline, inline-block, block, table, table-row, table-cell, flex, inline-flex, list-item !default;


@import "node_modules/bootstrap/scss/bootstrap";
