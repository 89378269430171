@import "bootstrap_override";

.blue-icon {
    color: $primary;
}

.orange-icon {
    color: coral;
}

.purple-icon {
    color: mediumpurple;
}
